// import {$,jQuery} from 'jquery';
// // // export for others scripts to use
// global.$ = $;
// global.jQuery = jQuery;
//$.noConflict();
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// 
// require("turbolinks").start()
// 
// 
// // Uncomment to copy all static images under ../images to the output folder and reference
// // them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// // or the `imagePath` JavaScript helper below.
// //
// // const images = require.context('../images', true)
// // const imagePath = (name) => images(name, true)
// 
// require("@rails/actiontext")
// import * as $ from 'jquery';
import Rails from "@rails/ujs"
import "../stylesheets/application.scss"
// import 'popper.js'
// import 'bootstrap';

// Importing jQuery in ES6 style
import $ from "jquery";

// We need to expose jQuery as global variable
window.jQuery = window.$ = $;

// ES6 import does not work it throws error: Missing jQuery 
// using Node.js style import works without problems
require('bootstrap');


require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")
// require("turbolinks").start()
Rails.start()
window.strftime = require('strftime')
// import 'moment'
// import 'eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js'
// import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css'
//import 'jquery-datetimepicker/jquery-datetimepicker.css'
// Rails.start()
// images
//require.context('../images/', true, /\.(gif|jpg|png|svg)$/i)


// document.addEventListener("turbolinks:load", () => {
//   $('[data-toggle="tooltip"]').tooltip()
// })
document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
})